import React, { FC, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Tour from "./pages/Tour";
import { SendContext, SendProps } from "./context";
import Home from "./pages/Home";
import Faq from "./pages/Faq";
import { I18nextProvider } from "react-i18next";
import i18n from "./translate/18n";

const App: FC = () => {
    const [isSend, setIsSend] = useState<SendProps>({
        success: "none",
        fail: "none",
    });
    return (
        <I18nextProvider i18n={i18n}>
            <SendContext.Provider value={{ isSend, setIsSend }}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/tour" element={<Tour />} />
                        <Route path="/faq" element={<Faq />} />
                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                </BrowserRouter>
            </SendContext.Provider>
        </I18nextProvider>
    );
};

export default App;
