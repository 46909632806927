import React from "react";

const Return = () => {
    return (
        <svg
            fill="#000000"
            height="40px"
            width="60px"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 469.411 469.411"
        >
            <g>
                <g>
                    <path
                        d="M397.305,207.826c-67.733-59.947-161.493-61.12-194.56-59.307V74.706c0-5.867-4.8-10.667-10.667-10.667
			c-2.453,0-4.907,0.853-6.827,2.453L3.918,215.826c-4.587,3.733-5.227,10.453-1.493,15.04c0.427,0.533,0.96,0.96,1.493,1.493
			l181.333,149.333c4.587,3.733,11.307,3.093,15.04-1.493c1.6-1.92,2.453-4.267,2.453-6.827v-77.013
			c34.667-8,175.147-30.507,246.613,103.36c1.813,3.52,5.44,5.653,9.387,5.653c3.413,0,6.72-1.6,8.853-4.693
			c1.28-1.813,1.813-4.053,1.813-6.293C469.305,312.999,445.091,250.279,397.305,207.826z M260.558,269.159
			c-41.067,0-70.72,8.427-71.467,8.64c-4.587,1.28-7.68,5.44-7.68,10.24v62.72l-153.92-126.72l153.92-126.72v62.72
			c0,5.867,4.8,10.667,10.667,10.667c0.427,0,0.853,0,1.28-0.107c1.173-0.107,115.2-12.907,189.76,53.227
			c35.2,31.147,56.213,75.2,62.72,130.987C391.758,284.306,315.811,269.159,260.558,269.159z"
                    />
                </g>
            </g>
        </svg>
    );
};

export default Return;
