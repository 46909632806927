import axios from "axios";
import React, { FC, useContext, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
    FormActionsContent,
    FormActionsGroup,
    FormActionsGroupInput,
    FormActionsGroupLabel,
    FormActionsSubmit,
    FormActionsWrapper,
} from "./styles";
import { SendContext } from "../../context";
import { useNavigate } from "react-router-dom";

interface IFormValues {
    name: string;
    telegram: string;
    phone: string;
    direction: string;
    startDate: string;
    endDate: string;
    count: string;
    children?: string;
    from: string;
}

const FormActions: FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { register, handleSubmit, reset } = useForm<IFormValues>();
    const { isSend, setIsSend } = useContext(SendContext);

    let dateEnd = "";
    let dateToday = new Date().toISOString().slice(0, 10);

    const onSubmit: SubmitHandler<IFormValues> = async (data) => {
        if (data.children?.length === 0) {
            delete data.children;
        }
        await sendData(data);
    };

    const sendData = async (data: IFormValues) => {
        await axios
            .post("https://api.sunseeker.cc/createTicket", data)
            .then((res) => {
                console.log(res);
                if (res.status === 200) {
                    setIsSend({
                        success: "block",
                        fail: "none",
                    });
                    setTimeout(() => {
                        setIsSend({
                            success: "none",
                            fail: "none",
                        });
                        reset();
                        navigate("/");
                    }, 6000);
                }
            })
            .catch((e) => {
                console.log(e);
                setIsSend({
                    success: "none",
                    fail: "block",
                });
                setTimeout(() => {
                    setIsSend({
                        success: "none",
                        fail: "none",
                    });
                }, 5000);
            });
    };

    return (
        <FormActionsWrapper>
            <FormActionsContent onSubmit={handleSubmit(onSubmit)}>
                <FormActionsGroup>
                    <FormActionsGroupInput
                        placeholder=" "
                        {...register("name")}
                        required
                    />
                    <FormActionsGroupLabel>
                        <span>* </span>
                        {t("form-name")}
                        <br />
                    </FormActionsGroupLabel>
                </FormActionsGroup>
                <FormActionsGroup />
                <FormActionsGroup>
                    <FormActionsGroupInput
                        placeholder=" "
                        required
                        {...register("telegram")}
                    />
                    <FormActionsGroupLabel>
                        <span>* </span>
                        {t("form-telegram")}
                    </FormActionsGroupLabel>
                </FormActionsGroup>
                <FormActionsGroup>
                    <FormActionsGroupInput
                        type="tel"
                        placeholder=" "
                        required
                        {...register("phone", {
                            pattern: /^\+[1-9]{1}[0-9]{3,14}$/,
                        })}
                    />
                    <FormActionsGroupLabel>
                        <span>* </span>
                        {t("form-phone")}
                    </FormActionsGroupLabel>
                </FormActionsGroup>

                <FormActionsGroup>
                    <FormActionsGroupInput
                        className="form_actions-input"
                        placeholder=" "
                        required
                        {...register("direction")}
                    />
                    <FormActionsGroupLabel>
                        <span>* </span>
                        {t("form-direction")}
                    </FormActionsGroupLabel>
                </FormActionsGroup>
                <FormActionsGroup>
                    <FormActionsGroupLabel style={{ textWrap: "wrap" }}>
                        <span>* </span>
                        {t("form-date")}
                    </FormActionsGroupLabel>
                    <br />
                    <br />
                    <br />
                    <FormActionsGroupInput
                        type="date"
                        min={`${dateToday}`}
                        placeholder=" "
                        required
                        {...register("startDate")}
                    />
                    <FormActionsGroupInput
                        type="date"
                        min={`${dateToday}`}
                        required
                        placeholder=" "
                        {...register("endDate")}
                    />
                </FormActionsGroup>
                <FormActionsGroup>
                    <FormActionsGroupInput
                        className="form_actions-input"
                        placeholder=" "
                        type="number"
                        required
                        min={1}
                        max={20}
                        {...register("count")}
                    />
                    <FormActionsGroupLabel>
                        <span>* </span>

                        {t("form-count")}
                    </FormActionsGroupLabel>
                </FormActionsGroup>
                <FormActionsGroup>
                    <FormActionsGroupInput
                        placeholder=" "
                        type="number"
                        min={0}
                        max={100}
                        {...register("children")}
                    />

                    <FormActionsGroupLabel>
                        {t("form-children")}
                    </FormActionsGroupLabel>
                </FormActionsGroup>
                <FormActionsGroup>
                    <FormActionsGroupInput
                        placeholder=" "
                        type="text"
                        required
                        {...register("from")}
                    />
                    <FormActionsGroupLabel>
                        <span>* </span>
                        {t("form-departure")}
                    </FormActionsGroupLabel>
                </FormActionsGroup>
                <FormActionsSubmit type="submit">
                    {t("form-submit")}
                </FormActionsSubmit>
            </FormActionsContent>
        </FormActionsWrapper>
    );
};

export default FormActions;
