import Close from "./icons/Close";
import Return from "./icons/Return";
import banner from "./img/form-img.png";
import logo from "./img/main-logo.jpg";
import faq from "./img/faq-img.png";
import Open from "./icons/Open";
export const Icons = {
    Return,
    Close,
    Open
};

export { banner, logo, faq };
