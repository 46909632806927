import styled from "styled-components";
import { size } from "../../styles";

export const FormActionsWrapper = styled.div`
    display: flex;
    justify-content: start;
`;
export const FormActionsContent = styled.form`
    width: 400px;
    @media (max-width: ${size.mobileL}) {
        width: 90%;
        display: flex;
        flex-direction: column;
    }
`;

export const FormActionsGroup = styled.div`
    position: relative;
    margin-bottom: 10px;
    width: 100%;
`;

export const FormActionsGroupInput = styled.input`
    width: 500px;
    height: 30px;
    margin-bottom: 20px;
    font-size: 16px;
    padding-left: 5px;
    border: none;
    border-bottom: 1px solid grey;
    background-color: transparent;
    outline: none;
    transition: 0.3s;
    &:focus {
        border-bottom: 1px solid black;
    }
    &:focus + label,
    &:not(:placeholder-shown) + label {
        top: -18px;
        font-size: 12px;
        color: black;
    }
    @media (max-width: ${size.mobileS}) {
        width: 100px;
    }
    @media (max-width: ${size.mobileM}) {
        width: 120%;
        margin-top: 10px;
        &:focus + label,
        &:not(:placeholder-shown) + label {
            top: -14px;
            font-size: 12px;
            color: black;
        }
    }
    @media (max-width: ${size.mobileL}) {
        width: 100%;
        padding: 5px;
        margin-bottom: 35px;
        margin-left: 10px;
    }
`;

export const FormActionsGroupLabel = styled.label`
    position: absolute;
    font-size: 15px;
    top: 0px;
    left: 0px;
    padding-left: 5px;
    padding-bottom: 5px;
    transition: 0.3s;
    z-index: -1;
    color: grey;
    width: 100%;
    text-wrap: nowrap;
    & span {
        color: red;
    }
    @media (max-width: ${size.mobileM}) {
        text-wrap: wrap;
    }
    @media (max-width: ${size.mobileL}) {
        padding-bottom: 10px;
        margin-left: 10px;
        text-wrap: wrap;
        top: -10px;
    }
`;

export const FormActionsSubmit = styled.button`
    margin-left: 35%;
    width: 250px;
    align-items: center;
    justify-content: center;
    appearance: none;
    background-image: radial-gradient(
        100% 100% at 100% 0,
        #5adaff 0,
        #5468ff 100%
    );
    border: 0;
    border-radius: 6px;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
        rgba(45, 35, 66, 0.3) 0 7px 13px -3px,
        rgba(58, 65, 111, 0.5) 0 -3px 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-family: "JetBrains Mono", monospace;
    height: 48px;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    text-align: center;
    text-decoration: none;
    transition: box-shadow 0.15s, transform 0.15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    font-size: 18px;

    &:focus {
        box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
            rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
    }

    &:hover {
        box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
            rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
        transform: translateY(-2px);
    }

    &:active {
        box-shadow: #3c4fe0 0 3px 7px inset;
        transform: translateY(2px);
    }

    @media (max-width: ${size.mobileL}) {
        width: 100%;
        padding: 5px;
        margin-left: 6%;
    }
`;
