import React, { FC, useState } from "react";
import styled from "styled-components";
import { Icons } from "../../assets";
import { size } from "../../styles";

const FaqItemWrapper = styled.div`
    width: 567px;
    min-height: 78px;
    padding: 24px 0px;
    border-bottom: 4px solid #d7def0;\
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    @media (max-width: ${size.mobileL}) {
        height: 10%;
        font-size: 16px;
        width: 100%;
    }
    @media (min-width: ${size.tablet}) {
        width: 350px;
        font-size: 16px;
    }

    @media (min-width: ${size.laptop}) {
        width: 567px;
        font-size: 18px;
    }
`;

const FaqItemQuestion = styled.div`
    display: flex;
    justify-content: space-between;
    cursor: pointer;
`;

const FaqItemAnswer = styled.div`
    margin-top: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: rgba(60, 60, 67, 0.85);
    @media (max-width: ${size.mobileL}) {
        font-size: 14px;
    }
    @media (min-width: ${size.tablet}) {
        font-size: 14px;
    }
    @media (min-width: ${size.laptop}) {
        font-size: 16px;
    }
`;

interface FaqItemProps {
    question: string;
    answer: string | React.ReactElement;
}

const FaqItem: FC<FaqItemProps> = ({ question, answer }) => {
    const [isSelected, setIsSelected] = useState<boolean>(false);
    return (
        <FaqItemWrapper>
            <FaqItemQuestion
                onClick={() => {
                    console.log("test");
                    setIsSelected(!isSelected);
                }}
            >
                <div>{question}</div>
                <div>{isSelected ? <Icons.Open /> : <Icons.Close />}</div>
            </FaqItemQuestion>
            <div>{isSelected && <FaqItemAnswer>{answer}</FaqItemAnswer>}</div>
        </FaqItemWrapper>
    );
};

export default FaqItem;
