import React, { FC, useState } from "react";
import styled from "styled-components";
import { logo } from "../assets";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { size } from "../styles";
import i18n from "i18next";

const HomeWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgb(255, 210, 229);
    background: radial-gradient(
        circle,
        rgba(255, 210, 229, 1) 0%,
        rgba(182, 215, 254, 1) 100%
    );
    color: black;
    /* & > select {
        align-items: start;
        color: red;
    } */

    @media (max-width: ${size.mobileL}) {
        width: 100vw;
        height: 120vh;
    }
`;
const HomeTitle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

const HomeTitleImg = styled.img`
    border-radius: 50%;
    margin-top: 70px;
    width: 110px;
    height: 110px;
    display: block;
    object-fit: cover;
    object-position: initial;
`;

const HomeSubtitle = styled.div`
    font-weight: 700;
    font-size: 20px;
    line-height: 1.5;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    margin-top: 15px;
`;

const HomeDescription = styled.div`
    padding: 0px;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.5;
    padding: 5px;
    border: 0px;
    vertical-align: baseline;
`;

const HomeButtons = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 488px;

    & a {
        text-decoration: none;
        color: rgb(255, 255, 255);
        width: 100%;
    }
    @media (max-width: ${size.mobileL}) {
        width: 100%;
        & a {
            text-decoration: none;
            color: rgb(255, 255, 255);
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
`;

const HomeButton = styled.button`
    display: flex;
    justify-content: center;
    font-weight: 600;
    margin-top: 32px;
    padding: 16px 44px;
    font-size: 16px;
    width: 488px;
    height: 64px;
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 10% / 100%;
    border: solid 3px black;
    background-color: rgba(255, 255, 255, 0);
    line-height: 1.5;
    color: black;
    cursor: pointer;

    &:hover {
        background-color: rgba(255, 255, 255, 1);
        color: black;
        transition: all 0.3s;
    }
    @media (max-width: ${size.mobileL}) {
        width: 70%;
    }
`;

const HomeLang = styled.select`
    padding: 10px 10px;
    margin-right: 20px;
    position: absolute;
    left: 58%;
    margin-top: 10px;
    background-size: cover;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 15px;
    border: solid 1px black;
    font-family: "Open Sans", "Helvetica Neue", "Segoe UI", "Calibri", "Arial",
        sans-serif;
    font-size: 18px;
    color: black;
    cursor: pointer;
    &:hover {
        opacity: 70%;
        transition: all 0.3s;
    }
`;

const Home: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    let lng = i18n.language === "ua" ? false : true;

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (e.target.value === "ua") {
            i18n.changeLanguage("ua");
        }
        if (e.target.value === "ru") {
            i18n.changeLanguage("ru");
        }
    };

    return (
        <HomeWrapper>
            <HomeLang onChange={handleChange}>
                <option selected={lng} value="ua">
                    UA
                </option>
                <option selected={lng} value="ru">
                    RU
                </option>
            </HomeLang>
            <HomeTitle>
                <HomeTitleImg src={logo} alt="img" />
                <div>
                    <HomeSubtitle>@sunseeker.eu</HomeSubtitle>
                    <HomeDescription>{t("home-title")}</HomeDescription>
                </div>
            </HomeTitle>
            <HomeButtons>
                <HomeButton
                    onClick={() => {
                        navigate("/tour");
                    }}
                >
                    {t("home-tour")}
                </HomeButton>
                <a href="https://www.instagram.com/p/C7R1HSdsJTY/?utm_source=ig_web_copy_link">
                    <HomeButton>{t("home-reviews")}</HomeButton>
                </a>

                <a href="https://www.instagram.com/sunseeker.eu/?hl=ru">
                    <HomeButton>Instagram</HomeButton>
                </a>
                <a href="https://t.me/sunseekereu">
                    <HomeButton>Telegram</HomeButton>
                </a>
                <HomeButton
                    onClick={() => {
                        navigate("/faq");
                    }}
                >
                    FAQ
                </HomeButton>
            </HomeButtons>
        </HomeWrapper>
    );
};

export default Home;
