export const faqUa = [
    {
        question: "Хто ми такі?",
        answer: (
            <p>
                Нас звати Богдан і Дар&rsquo;я, приємно познайомитися 🥰.
                <br />
                Ми - любителі подорожей не за всі гроші світу! Ми побували в
                більш ніж 20 країнах, а знаходимося у Празі. Наша мета -
                ділитися своїм досвідом і просувати бюджетний відпочинок серед
                усіх, хто мріє подорожувати без зайвих витрат! Ми завжди
                відкриті для спілкування і прозорі у своїй діяльності!👏🏻
            </p>
        ),
        id: 1,
    },
    {
        question: "Чому так дешево?",
        answer: (
            <p>
                Як ми вже згадали, ми - любителі <strong>бюджетного</strong>{" "}
                відпочинку. Завдяки нашому досвіду в подорожах, ми накопичили
                цінні знання і готові ділитися ними з вами, щоб допомогти вам
                відкрити <strong>світ подорожей. </strong>
                <br />У вартість наших турів включено: <br />- Авіаквитки в
                обидва боки
                <br />- Проживання на всі дні туру <br />- Наша онлайн підтримка
                24/7
            </p>
        ),
        id: 2,
    },
    {
        question: "Чи є у вас офіс?",
        answer: (
            <p>
                На даний момент у нас немає фізичного офісу, хоча ми не
                виключаємо його появи в майбутньому. <br />
                Оскільки ми онлайн-турагенція і обслуговуємо клієнтів по{" "}
                <strong>всій</strong> Європі, наявність офісу в одному місті
                здається нам недоцільною. Однак, ви завжди можете зустрітися з
                нами <strong>особисто</strong> в Празі
            </p>
        ),
        id: 3,
    },
    {
        question: "Як відбувається бронювання туру?",
        answer: (
            <p>
                Ціни на тури актуальні <strong>тільки</strong> в день
                бронювання, оскільки ринок авіаквитків нестабільний і ціни
                можуть змінитися вже наступного дня😢
            </p>
        ),
        id: 4,
    },
];
