import React, { createContext } from "react";
export type ContainerProps = {
    children: React.ReactNode;
};

export interface SendProps {
    success: string;
    fail: string;
}

type TypeSendContextType = {
    isSend: SendProps;
    setIsSend: React.Dispatch<React.SetStateAction<SendProps>>;
};
const typeSendContextState = {
    isSend: {
        success: "none",
        fail: "none",
    },
    setIsSend: () => {},
};

export const SendContext =
    createContext<TypeSendContextType>(typeSendContextState);
