import React, { FC, useContext } from "react";
import styled from "styled-components";
import MyForm from "../components/Form/MyForm";
import { banner } from "../assets";
import { useTranslation } from "react-i18next";
import { SendContext } from "../context";
import { size } from "../styles";

const FormWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    margin: 0;
    display: flex;
    justify-content: center;
`;
const Form = styled.div`
    height: 100%;
    max-width: 650px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: start;
    border-radius: 10px;
    box-shadow: 0 4px 25px #ccc;
    overflow: hidden;
    margin-top: 20px;
`;

const FormBanner = styled.img`
    width: 100%;
    height: 200px;
    padding: 0;
    object-fit: cover;
    @media (max-width: ${size.mobileL}) {
        font-size: 14px;
    }
`;
interface FormSendProps {
    show: string;
}

const FormSendFail = styled.div<FormSendProps>`
    animation: ani 0.5s;
    @keyframes ani {
        0% {
            opacity: 0;
            top: 0px;
        }
        100% {
            opacity: 1;
            top: 25px;
        }
    }
    position: fixed;
    top: 25px;
    background-color: #ffbaba;
    font-size: 20px;
    border: 2px solid red;
    border-radius: 10px;
    width: 300px;
    text-align: center;
    padding: 10px;
    height: 50px;
    display: ${(props) => (props.show === "block" ? "block" : "none")};
`;

const FormSendSuccess = styled.div<FormSendProps>`
    animation: ani 0.5s;
    @keyframes ani {
        0% {
            opacity: 0;
            top: 0px;
        }
        100% {
            opacity: 1;
            top: 30px;
        }
    }
    position: fixed;
    top: 30px;
    background-color: hsla(84, 100%, 59.2%, 0.8);
    font-size: 20px;
    border: 2px solid green;
    border-radius: 10px;
    width: 300px;
    text-align: center;
    padding: 10px;
    height: 50px;
    display: ${(props) => (props.show === "block" ? "block" : "none")};
`;
const Tour: FC = () => {
    const { t } = useTranslation();
    const { isSend, setIsSend } = useContext(SendContext);
    return (
        <FormWrapper>
            <FormSendSuccess show={isSend.success}>
                {t("form-success")}
            </FormSendSuccess>
            <FormSendFail show={isSend.fail}>{t("form-fail")}</FormSendFail>
            <Form>
                <FormBanner src={banner} alt="sunseeker banner" />
                <MyForm />
            </Form>
        </FormWrapper>
    );
};

export default Tour;
