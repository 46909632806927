export const faqRu = [
    {
        question: "Кто мы?",
        answer: (
            <p>
                Нас зовут Богдан и Дарья, приятно познакомиться 🥰.&nbsp;
                <br />
                Мы - любители путешествий не за все деньги мира! Мы побывали в
                более чем 20 странах, а находимся в Праге. Наша цель - делиться
                своим опытом и продвигать бюджетный отдых среди всех, кто
                мечтает путешествовать без лишних затрат! Мы всегда открыты для
                общения и прозрачны в своей деятельности! 👏🏻
            </p>
        ),
        id: 1,
    },
    {
        question: "Почему так дешево?",
        answer: (
            <p>
                Как мы уже упомянули, мы - любители <strong>бюджетного</strong>{" "}
                отдыха. Благодаря нашему опыту в путешествиях, мы накопили
                ценные знания и готовы делиться ими с вами, чтобы помочь вам
                открыть <strong>мир путешествий</strong>.<br />В стоимость наших
                туров включено:
                <br />- Авиабилеты в обе стороны
                <br />- Проживание на все дни тура
                <br />- Наша онлайн поддержка 24/7
            </p>
        ),
        id: 2,
    },
    {
        question: "Есть ли у вас офис?",
        answer: (
            <p>
                На данный момент у нас нет физического офиса, хотя мы не
                исключаем его появления в будущем.
                <br />
                Поскольку мы онлайн-турагенция и обслуживаем клиентов по{" "}
                <strong>всей</strong> Европе, наличие офиса в одном городе
                кажется нам нецелесообразным. Однако, вы всегда можете
                встретиться с нами <strong>лично</strong> в Праге.
            </p>
        ),
        id: 3,
    },
    {
        question: "Как происходит бронирование тура?",
        answer: (
            <p>
                Цены на туры актуальны <strong>только</strong> в день
                бронирования, поскольку рынок авиабилетов нестабилен и цены
                могут измениться уже на следующий день😢
            </p>
        ),
        id: 4,
    },
];
