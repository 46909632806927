import React, { FC, useEffect } from "react";
import styled from "styled-components";
import { faq } from "../assets";
import FaqItem from "../components/FAQ/FaqItem";
import { faqRu } from "../assets/data/faq-data-ru";
import { faqUa } from "../assets/data/faq-data-ua";
import i18n from "i18next";
import { size } from "../styles";

const FaqWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 64px 130px;
    @media (max-width: ${size.mobileL}) {
        flex-direction: column;
        padding: 16px 32px;
        align-items: start;
    }
`;

const FaqImg = styled.img`
    width: 567px;
    height: 425px;
    @media (max-width: ${size.mobileL}) {
        width: 100%;
        height: 10%;
    }
    @media (min-width: ${size.tablet}) {
        width: 320px;
        height: 240px;
    }
    @media (min-width: ${size.laptop}) {
        width: 567px;
        height: 425px;
    }
`;

const FaqItems = styled.div`
    margin-left: 46px;
    @media (max-width: ${size.mobileL}) {
        margin-left: 5px;
    }
`;

const Faq: FC = () => {
    let faqData = i18n.language === "ua" ? faqUa : faqRu;

    return (
        <FaqWrapper>
            <FaqImg src={faq} alt="faq-img" />
            <FaqItems>
                {faqData.map((item) => {
                    return (
                        <FaqItem
                            question={item.question}
                            answer={item.answer}
                        />
                    );
                })}
            </FaqItems>
        </FaqWrapper>
    );
};

export default Faq;
