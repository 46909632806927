import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import FormActions from "./FormActions";
import { Icons } from "../../assets";
import { size } from "../../styles";
import { useNavigate } from "react-router-dom";


const FormMain = styled.div`
    padding: 60px 60px 60px 60px;
    min-height: 1000px;
    width: 100%;
    @media (max-width: ${size.mobileL}) {
        width: 100%;
        font-size: 14px;
        padding: 5px;
    }
`;
const FormContent = styled.div`
    /* padding: 0 60px 60px 60px; */
`;

const FormHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const FormHeaderButton = styled.button`
    height: 40px;
    width: 60px;
    background-color: white;
    border: none;
    cursor: pointer;
    &:hover {
        opacity: 70%;
        transform: translateX(-5px);
        transition: all 0.3s;
    }
`;
const FormContentTitle = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
    text-align: center;
    height: 100px;
    & h1 {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        text-align: center;
        height: 100px;
    }
`;

const FormContentDescription = styled.div`
    color: grey;
    margin-bottom: 40px;
    @media (max-width: ${size.mobileL}) {
        padding: 10px;
    }
    @media (max-width: ${size.mobileM}) {
        text-wrap: wrap;
    }
`;
const MyForm: FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <FormMain>
            <FormContent>
                <FormHeader>
                    <FormHeaderButton
                        onClick={() => {
                            navigate("/");
                        }}
                    >
                        <Icons.Return />
                    </FormHeaderButton>
                </FormHeader>
                <FormContentTitle>
                    <h1>{t("content-title")}</h1>
                </FormContentTitle>
                <FormContentDescription>
                    {t("content-description")}
                </FormContentDescription>
            </FormContent>
            <FormActions />
        </FormMain>
    );
};

export default MyForm;
