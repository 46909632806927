import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import ru from "./locales/ru/translation.json";
import ua from "./locales/ua/translation.json";

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            ru: {
                translation: ru,
            },
            ua: {
                translation: ua,
            },
        },
        lng: "ua",
        fallbackLng: "ua",
        debug: true,
        detection: {
            order: ["queryString", "cookie"],
            cache: ["cookie"],
        },

        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
